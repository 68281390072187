import { BASE_URL } from "../config/settings";
import httpRequest from "../utils/httpRequest";

export const validateTeacher = async (msisdn, accountNumber, nationalId) => {
  return await httpRequest(
    `${BASE_URL}/sacco/eligibility?msisdn=${msisdn}&accountNumber=${accountNumber}&nationalId=${nationalId}`
  );
};

export const registerCorporateCustomer = async (payload) => {
  return await httpRequest(
    `${BASE_URL}/loans/apply-for-corporate-loan`,
    "post",
    payload
  );
};

export const validateCorporateCustomer = async (msisdn) => {
  return await httpRequest(
    `${BASE_URL}/customers/validate-corporate-customer-by-msisdn`,
    "post",
    { msisdn }
  );
};

export const validateCorporateCustomerOTP = async (msisdn, otp) => {
  return await httpRequest(
    `${BASE_URL}/customers/validate-corporate-customer-otp-by-msisdn`,
    "post",
    { msisdn, otp }
  );
};

export const getCorporateCustomer = async (id) => {
  return await httpRequest(
    `${BASE_URL}/get-corporate-customer-by-nationalid`,
    "post",
    { id }
  );
};

export const adminLogin = async (payload) => {
  return await httpRequest(
    `${BASE_URL}/auth/authenticate-corporate-admin`,
    "post",
    payload
  );
};
